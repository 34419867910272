







































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { Avatar, Empty } from '@h3/antd-vue';

import { WorkPlatformService } from '@WorkPlatform/services/index';

import { isPC, isPhone, getImageUrl } from '@WorkPlatform/common/utils';

import { service } from '@cloudpivot-hermes/nail-free-login';

import { CardInformation } from '@cloudpivot7-business-components/pc';

import moment from 'moment';
import { CommonServices } from '@WorkPlatform/common/service';

@Component({
  name: 'todo-list',
  components: {
    AAvatar: Avatar,
    AEmpty: Empty,
    CardInformation,
  },
})
export default class ComponentName extends Vue {
  @Prop({ default: [] }) list!: any[];

  @Prop({ default: () => 0 }) tabKey?: number;

  emptyImgUrl = require('@/assets/images/empty.svg');

  get IsDingTalk () {
    return sessionStorage.IS_DINGTALK === 'true';
  }

  get isFreeLoginPlatform () {
    return (
      sessionStorage.IS_DINGTALK === 'true' ||
      sessionStorage.IS_WECHAT === 'true'
    );
  }

  get ISWECHAT () {
    return sessionStorage.IS_WECHAT === 'true';
  }

  get userAvatar () {
    return {
      backgroundImage: `url(${require('@/assets/images/tx.png')})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: '32px',
      height: '32px',
    };
  }

  getImgUrl (img) {
    return getImageUrl(img);
  }

  calcTimeout (item: any) {
    if (item.timeoutStatus) {
      return item.allowedTime ? this.timeOut(item.allowedTime) : 0;
    }
    return item.usedtime ? this.formatSeconds(item.usedtime / 1000) : 0;
  }

  /**
   * 跳转待办详情
   */
  clickHandler (item: any) {
    const params: any = {
      appCode: item.appCode,
      appVersion: item.appVersion,
    };

    const winOpen: any = !this.isFreeLoginPlatform && window.open('', '_blank');

    WorkPlatformService.getAppUrl(params).then((res: any) => {
      if (res.success) {
        let pageArr: any[] = [];

        try {
          pageArr =
            typeof item.pageJson === 'string' ? JSON.parse(item.pageJson) : [];
        } catch (e) { }

        let pageJson: any;

        for (const page of pageArr) {
          if (page.pageType !== 'ALL') {
            if (isPC() && page.pageType === 'PC') {
              pageJson = page;
              break;
            } else if (isPhone() && page.pageType === 'MOBILE') {
              pageJson = page;
              break;
            } else {
              pageJson = page;
            }
          } else {
            pageJson = page;
            break;
          }
        }

        let query: any = {
          groupCode: '',
          pageGroupCode: '',
          pageCode: '',
          workItemId: item.id,
          workflowInstanceId: item.instanceId,
          appVersion: item.appVersion,
          workflowCode: item.workflowCode,
          isFromTask: true, // 流程-ui解耦新增参数
          ...pageJson,
        };
        query = CommonServices.setLoginParam(query);
        const str: string = Object.entries(query)
          .map((queryArr: any[]) => {
            return queryArr.join('=');
          })
          .join('&');

        let url = `${res.data}?${str}`;

        if (process.env.NODE_ENV !== 'production') {
          url = `http://localhost${res.data.substring(
            res.data.indexOf('/app_launcher/'),
          )}?${str}`;
        }

        try {
          const token = localStorage.getItem('token');
          const wait: number = 100;
          if (this.ISWECHAT && isPhone()) {
            window.open(`${url}&token=${token}`);
          } else if (this.isFreeLoginPlatform) {
            service.openLink(`${url}&token=${token}`);
          } else {
            if (!winOpen) {
              throw new Error('浏览器新开弹窗未开启');
            }

            setTimeout(() => {
              winOpen.location = url;
            }, wait);
          }
        } catch (e) {
          Vue.prototype.$errorcatcher.showMessagePopup({
            title: '错误提示！',
            message: [e, '!'],
            displayFooter: false,
            correctGuide: '请检查浏览器是否开启禁止新开弹窗！',
          });
        }
      }
    });
  }

  formatSeconds (value: any): string {
    if (!value) return '--';
    const unit: number = 10;
    const aHour: number = 60;
    const aDay: number = 24;
    const empty: number = 0;
    let theTime: number = parseInt(value, unit); // 需要转换的时间秒
    let theTime1: number = 0; // 分
    let theTime2: number = 0; // 小时
    let theTime3: number = 0; // 天

    if (theTime > aHour) {
      theTime1 = parseInt(String(theTime / aHour), unit);
      theTime = parseInt(String(theTime % aHour), unit);
      if (theTime1 > aHour) {
        theTime2 = parseInt(String(theTime1 / aHour), unit);
        theTime1 = parseInt(String(theTime1 % aHour), unit);
        if (theTime2 > aDay) {
          // 大于24小时
          theTime3 = parseInt(String(theTime2 / aDay), unit);
          theTime2 = parseInt(String(theTime2 % aDay), unit);
        }
      }
    }
    let result = '';
    if (theTime > empty) {
      result = '' + parseInt(theTime + '', unit) + '秒';
    }
    if (theTime1 > empty) {
      result = '' + parseInt(theTime1 + '', unit) + '分' + result;
    }
    if (theTime2 > empty) {
      result = '' + parseInt(theTime2 + '', unit) + '小时' + result;
    }
    if (theTime3 > empty) {
      result = '' + parseInt(theTime3 + '', unit) + '天' + result;
    }

    return result;
  }

  get userInfo () {
    let publicUserInfo: any = localStorage.getItem('publicUserInfo');
    if (publicUserInfo) {
      publicUserInfo = JSON.parse(publicUserInfo) || {};
      const userInfo: any = publicUserInfo || {};
      return userInfo;
    }
    return {
      name: '-',
      mobile: '-',
    };
  }

  timeOut (value: string) {
    const oldTime: any = moment(value, 'YYYY-MM-DD hh:mm:ss').utc(); // new Date(str) safari incapitble
    const newTime: any = new Date().getTime();
    const timeout: any = newTime - oldTime;
    const unit: number = 1000;
    return `已超时${this.formatSeconds(timeout / unit)}`;
  }

  get token () {
    return localStorage.getItem('token');
  }
}
